import React from 'react'

const CodeExampleBlock = ({ displayType, codeSections, footnoteText, footnoteIcon }) => {
    let classBox = displayType === 'White box with shadow' ? 'white-box' : 'black-box'
    return (
        <div className={`code-block ${classBox}`}>
            <div className="three-dots">
                <span className="red"></span>
                <span className="yellow"></span>
                <span className="green"></span>
            </div>
            <div className="code-wrapper">
                <pre>
                    {
                        codeSections && codeSections.map((code, index) => {
                            return (
                                <code key={index}>
                                    {code.sampleCode}
                                </code>
                            )
                        })
                    }
                </pre>

            </div>
            {
                footnoteText && (
                    <div className="preview">
                        {footnoteIcon && (<div className="logo" dangerouslySetInnerHTML={{ __html: footnoteIcon }}></div>)}
                        <div className="text">
                            <p>{footnoteText}</p>
                        </div>
                    </div>
                )
            }

        </div>
    )
}

export default CodeExampleBlock
