import React from 'react'
import { LightgalleryItem, LightgalleryProvider } from "react-lightgallery";
import PT from "prop-types";
import { imageSizeUrl } from '../../helper'

const GalleryBlock = ({ headline, gallery }) => {
    const className = gallery.length === 1 ? 'image-wrapper col-12' : 'col-sm-6 col-md-3'
    const imageSize = gallery.length === 1 ? 'medium_large' : 'medium'
    const PhotoItem = ({ image, alt, thumb, group }) => (
        <LightgalleryItem group={group} src={image} thumb={thumb}>
            <div className="image-item" style={{ backgroundImage: `url(${thumb})` }}>
                <img src={image} alt={alt} />
            </div>
        </LightgalleryItem>
    );

    PhotoItem.propTypes = {
        image: PT.string.isRequired,
        alt: PT.string.isRequired,
        thumb: PT.string,
        group: PT.string.isRequired
    };

    return (
        <div className="gallery-wrapper">
            {
                headline && (
                    <div className="headline"><h4>{headline}</h4></div>
                )
            }
            {
                <div className="row">
                    <LightgalleryProvider>
                        {
                            gallery.map((image, index) => {
                                return (
                                    <div key={index} className={className}>
                                        <PhotoItem image={image.mediaItemUrl} alt={image.title} thumb={imageSizeUrl(imageSize, image.mediaDetails.sizes, image.mediaItemUrl)} group="any" />
                                    </div>
                                )
                            })
                        }
                    </LightgalleryProvider>
                </div>
            }
        </div>
    )
}

export default GalleryBlock
